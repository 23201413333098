<template>
<!-- breadcrumb area start -->
<!--
<div class="breadcrumb-area" style="background-image:url(cdn/assets/img/page-title-bg.png);">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="breadcrumb-inner">
                    <h1 class="page-title">Contact</h1>
                    <ul class="page-list">
                        <li><a href="#" to="/">Home</a></li>
                        <li>Contact</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>-->
<!-- breadcrumb area End -->

<!-- contact form start -->
<div class="contact-form-area pd-top-112">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-6 col-lg-8">
                <div class="section-title text-center w-100">
                    <h2 class="title">Send you <span>inquary</span></h2>
                    <p></p>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-xl-4 col-lg-5">
                <img src="cdn/assets/img/others/21.png" alt="blog">
            </div>
            <div class="col-lg-7 offset-xl-1">
                <form class="riyaqas-form-wrap mt-5 mt-lg-0">
                    <div class="row custom-gutters-16">
                        <div class="col-md-6">
                            <div class="single-input-wrap">
                                <input type="text" class="single-input">
                                <label>Name</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="single-input-wrap">
                                <input type="text" class="single-input">
                                <label>E-mail</label>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="single-input-wrap">
                                <input type="text" class="single-input">
                                <label>Subject</label>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="single-input-wrap">
                                <textarea class="single-input textarea" cols="20"></textarea>
                                <label class="single-input-label">Message</label>
                            </div>
                        </div>
                        <div class="col-12">
                              <router-link class="btn btn-red mt-0" to="/contactus">Send</router-link>
                           
                        </div> 
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
</template>
<script>

export default {
  
  methods: {
    goTo(url) {
      location.href = url;
    },
  },
};
</script>