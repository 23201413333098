<template>
  <!-- breadcrumb area start -->
  <!--
  <div
    class="breadcrumb-area"
    style="background-image: url(cdn/assets/img/page-title-bg.png)"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="breadcrumb-inner">
            <h1 class="page-title">Our Products</h1>
            <ul class="page-list">
              <li><a href="#">Home</a></li>
              <li>Products</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>-->
  <!-- breadcrumb area End -->

  <div class="blog-page-area pd-top-120">
    <div class="container">
      <div class="row custom-gutters-60">
        <div class="col-lg-12">
          <div class="row">
          <!-- {{ this.productdata}}-->
            <img src="cdn/assets/img/products/Products details_pages-to-jpg-0030.jpg" alt="client" v-if="!this.ishide">
            <div
              class="col-lg-6"
              v-for="product in this.productdata"
              :key="product.id"
            >
              <div class="single-blog-content style-two">
               <!-- <div class="thumb">
                  <img :src="product.image" alt="Product Image" />
                  
                </div>-->
                 <div class="img-with-video img-with-video-2 wow animated fadeInRight" data-wow-duration="1s"
                        data-wow-delay="0.3s">
                        <div class="img-wrap">
                              <img :src="product.blogimage" alt="Product Image" />
                            <div class="hover">
                                <a :href="product.youtubeUrl"
                                    class="video-play-btn mfp-iframe"><img src="cdn/assets/img/we-provide/3.png"
                                        alt="video"></a>
                            </div>
                        </div>
                    </div>
                <div class="single-blog-details">
                  <h5>
                    {{ product.blogname }}
                  </h5>
                  <p v-html="product.blogtitle"></p>
                  <p v-html="product.blogsummary"></p>
                  <a class="readmore" @click="goTo('/productdetail/'+product.blogid)"
                    >Read More ({{product.blogid}})
                    <span><i class="la la-long-arrow-right"></i></span
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      productdata: null,
      ishide:false
    };
  },
  methods: {
    goTo (url) {
      return this.$router.push(url)
      //window.location.href=url;
    },
  },
  mounted() {
    // Example GET request
     //.get("data/products.json")
    axios     
      .get("https://webainitnils.azurewebsites.net/app_getallblog")
      .then((response) => {
        // Handle response data
        this.productdata = response.data.result;
        this.ishide=true;
        //alert('hi' + JSON.stringify(this.productdata));
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching JSON data:", error);
      });
  },
};
</script>
<style scoped>
.readmore{
  cursor: pointer;
}
</style>
