<template>
<!-- header area start -->
    <div class="header-area sbst-banner-area">
        <div class="container">
            <div class="row">
                <div class="col-xl-4 col-lg-6 col-md-6 offset-xl-1">
                    <div class="header-inner-details">
                        <div class="header-inner">
                            <h1 class="title wow animated fadeInUp" data-wow-duration="1s" data-wow-delay="0s">
                                Innovative <br>Health Solutions <br> for <span>You</span></h1>
                            <p class=" wow animated fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s">"New India
                                Life Sciences: Pioneering Health Solutions for Every Community. Quality, Accessibility,
                                Trust – Your Partner in Wellness."</p>
                            <div class="btn-wrapper desktop-left padding-top-20 wow animated fadeInUp"
                                data-wow-duration="1s" data-wow-delay="0.6s">
                               
                                <router-link to="/ourproduct" class="btn btn-radius btn-green">Products</router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 offset-xl-1 hidden-sm">
                    <div class="banner-animate-thumb">
                        <div class="header-img-1 wow animated fadeInRight" data-wow-duration="1s" data-wow-delay="0s">
                            <img src="cdn/assets/img/startup/slider/1.png" alt="banner-img">
                        </div>
                        <div class="header-img-2 wow animated fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s">
                            <img src="cdn/assets/img/startup/slider/2.png" alt="banner-img">
                        </div>
                        <div class="header-img-3 wow animated fadeInDown" data-wow-duration="1s" data-wow-delay="0.8s">
                            <img src="cdn/assets/img/startup/slider/3.png" alt="banner-img">
                        </div>
                        <div class="header-img-4 wow animated fadeInDown" data-wow-duration="1s" data-wow-delay="1.0s">
                            <img src="cdn/assets/img/startup/slider/4.png" alt="banner-img">
                        </div>
                        <div class="header-img-5 wow animated fadeInDown" data-wow-duration="1s" data-wow-delay="1.3s">
                            <img src="cdn/assets/img/startup/slider/5.png" alt="banner-img">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- header area End -->

   <!-- sbst service area start -->
    <div class="sbst-service-area">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-8 col-lg-10">
                    <div class="section-title text-center">
                        <h2 class="title wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.1s">What We
                            <span>Do?</span>
                        </h2>
                        <p class="wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.2s">"At New India
                            Life Sciences, we specialize in developing and providing high-quality, affordable medicines
                            to support individuals in their journey towards better health. Through our relentless
                            dedication and cutting-edge technology, we ensure that patients have access to the
                            treatments they need, wherever they are. From research and development to production and
                            distribution, we are committed to improving lives and making a positive impact on
                            communities worldwide."</p>
                    </div>
                </div>
            </div>
            <!--
            <div class="row custom-gutters-28 justify-content-center">
                <div class="col-lg-3 col-sm-6">
                    <div class="single-service-2 text-center wow animated fadeInUp" data-wow-duration="0.6s"
                        data-wow-delay="0.1s">
                        <div class="thumb">
                            <img src="cdn/assets/img/startup/service/1.png" alt="service">
                        </div>
                        <h6>Vision</h6>
                        <p>New India Life Sciences vision is to be among the leading healthcare company which uses or
                            comprises technology and innovation to cater the everyday needs of human beings. </p>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="single-service-2 text-center wow animated fadeInUp" data-wow-duration="0.6s"
                        data-wow-delay="0.3s">
                        <div class="thumb">
                            <img src="cdn/assets/img/startup/service/2.png" alt="service">
                        </div>
                        <h6>Mission</h6>
                        <p> to provide dynamic, scientifically advanced, cost-effective, and high-quality healthcare
                            solutions to communities worldwide. Through relentless innovation, we aim to revolutionize
                            the healthcare landscape, ensuring access to cutting-edge treatments that enhance lives and
                            promote well-being.</p>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="single-service-2 text-center wow animated fadeInUp" data-wow-duration="0.6s"
                        data-wow-delay="0.5s">
                        <div class="thumb">
                            <img src="cdn/assets/img/startup/service/3.png" alt="service">
                        </div>
                        <h6>Values</h6>
                        <p>Riyaqas One of the best Software Development company.Riyaqas is the forefront as the most
                            innovative web.</p>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="single-service-2 text-center wow animated fadeInUp" data-wow-duration="0.6s"
                        data-wow-delay="0.7s">
                        <div class="thumb">
                            <img src="cdn/assets/img/startup/service/4.png" alt="service">
                        </div>
                        <h6>Quality</h6>
                        <p>Riyaqas One of the best Software Development company.Riyaqas is the forefront as the most
                            innovative web.</p>
                    </div>
                </div>
            </div>-->
        </div>
    </div>
    <!-- sbst service area end -->
 <!-- vision area start -->
    <div class="sbst-service-area">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-8 col-lg-10">
                    <div class="section-title text-center">
                        <h2 class="title wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.1s">Our
                            <span>Vision</span>
                        </h2>
                        <p class="wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.2s">"New India Life Sciences vision is to be among the leading healthcare company which uses or comprises technology and innovation to cater the everyday needs of human beings."</p>
                    </div>
                </div>
            </div>
          
        </div>
    </div>
    <!-- vision  area end -->
    <!-- Mission area start -->
    <div class="sbst-service-area">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-8 col-lg-10">
                    <div class="section-title text-center">
                        <h2 class="title wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.1s">Our
                            <span>Mission</span>
                        </h2>
                        <p class="wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.2s">"To provide Dynamic, Scientific, Cost Effective and Quality Healthcare solutions to the global community through innovation"</p>
                    </div>
                </div>
            </div>
          
        </div>
    </div>
    <!-- Mission  area end -->
     <!-- Value area start -->
    <div class="sbst-service-area">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-8 col-lg-10">
                    <div class="section-title text-center">
                        <h2 class="title wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.1s">Our
                            <span>Value</span>
                        </h2>
                        <p class="wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.2s">"Our values reflect who we are and what we stand for as a company. "</p>
                    </div>
                </div>
            </div>
          
        </div>
    </div>
    <!-- Value  area end -->
    <!-- Quality area start -->
    <div class="sbst-service-area">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-8 col-lg-10">
                    <div class="section-title text-center">
                        <h2 class="title wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.1s">Our
                            <span>Quality</span>
                        </h2>
                        <p class="wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.2s">"We provide outstanding products and unsurpassed service that, together, deliver premium value to our customers. "</p>
                    </div>
                </div>
            </div>
          
        </div>
    </div>
    <!-- Value  area end -->
     <!-- Quality area start -->
    <div class="sbst-service-area">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-8 col-lg-10">
                    <div class="section-title text-center">
                        <h2 class="title wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.1s">QUALITY IS NOT AN ACT, IT IS AN HABIT.
                        </h2>
                      
                    </div>
                </div>
            </div>
          
        </div>
    </div>
    <!-- Value  area end -->
    <div class="sbst-provide-security mg-top-30 mg-bottom-10"
        style="background-image:url(assets/img/startup/why-choose/2.png);">
        <div class="container">
            <div class="row">
                <div class="col-xl-7 col-lg-6">
                    <div class="img-with-video img-with-video-2 wow animated fadeInRight" data-wow-duration="1s"
                        data-wow-delay="0.3s">
                        <div class="img-wrap">
                            <img src="cdn/assets/img/startup/why-choose/1.png" alt="video">
                            <div class="hover">
                                <a href="https://www.youtube.com/watch?v=um9dcZn5c6g"
                                    class="video-play-btn mfp-iframe"><img src="cdn/assets/img/we-provide/3.png"
                                        alt="video"></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-5 col-lg-6 desktop-center-item">
                    <div class="wow animated fadeInLeft" data-wow-duration="1s" data-wow-delay="0.3s">
                        <div class="section-title style-two">
                            <h2 class="title">Why Choose <span>Us?</span></h2>
                            <p>"New India Life Sciences stands as a beacon of innovation and dedication, offering affordable, cutting-edge healthcare solutions globally. With a trusted reputation and a customer-centric approach, we ensure access to high-quality medicines, empowering communities to lead healthier lives and fostering a brighter future for all."</p>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="riyaqas-check-list">
                                    <img src="cdn/assets/img/icons/check.png" alt="check">
                                    <span>Global Trust</span>
                                </div>
                                <div class="riyaqas-check-list">
                                    <img src="cdn/assets/img/icons/check.png" alt="check">
                                    <span>Affordable Excellence</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="riyaqas-check-list">
                                    <img src="cdn/assets/img/icons/check.png" alt="check">
                                    <span>Innovative Healthcare</span>
                                </div>
                                <div class="riyaqas-check-list">
                                    <img src="cdn/assets/img/icons/check.png" alt="check">
                                    <span>Customer-Centric Solutions</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<!--
    <div class="sbst-skill-area pd-top-120">
        <div class="container">
            <div class="row">
                <div class="col-xl-5 col-lg-6 desktop-center-item">
                    <div class="animated fadeInRight" data-wow-duration="1s" data-wow-delay="0.3s">
                        <div class="section-title style-two">
                            <h2 class="title">We Have Strong <span>Skill</span></h2>
                            <p>SoftBox One of the best Softwire Development company. SoftBox is at the forefront as the
                                most innovative web development company. Our cost efficient and structured teamwork
                                justifies our position.</p>
                        </div>
                        <div class="all-progress" id="progress-running">
                            <div class="progress-item">
                                <span class="progress-heading">User Interface Design</span>
                                <span class="progress-count">95%</span>
                                <div class="progress-bg">
                                    <div id='progress-one' class="progress-rate" data-value='95'>
                                    </div>
                                </div>
                            </div>
                            <div class="progress-item">
                                <span class="progress-heading">User Experience</span>
                                <span class="progress-count">86%</span>
                                <div class="progress-bg">
                                    <div id='progress-two' class="progress-rate" data-value='86'>
                                    </div>
                                </div>
                            </div>
                            <div class="progress-item">
                                <span class="progress-heading">Market Analysis</span>
                                <span class="progress-count">90%</span>
                                <div class="progress-bg">
                                    <div id='progress-three' class="progress-rate" data-value='90'>
                                    </div>
                                </div>
                            </div>
                            <div class="progress-item mg-bottom-0-991">
                                <span class="progress-heading">Market Analysis</span>
                                <span class="progress-count">80%</span>
                                <div class="progress-bg">
                                    <div id='progress-four' class="progress-rate" data-value='80'>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-5 offset-xl-1 col-lg-6">
                    <div class="shape-bg-image animated fadeInLeft" data-wow-duration="1s" data-wow-delay="0.3s">
                        <div class="thumb">
                            <img src="cdn/assets/img/startup/skill/1.png" alt="service">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
-->

    <!-- offer area start -->
<!--
    <div class="sbst-offer-area pd-top-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-7 col-lg-10">
                    <div class="section-title text-center">
                        <h2 class="title wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.1s">What We
                            are <span>Offering?</span></h2>
                        <p class="wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.2s">Riyaqas One of
                            the best Software Development company. Riyaqas is at the forefront as the most <br>
                            innovative web development company. Our cost efficient and structured teamwork.</p>
                    </div>
                </div>
            </div>
            <div class="row custom-gutters-28 justify-content-center">
                <div class="col-xl-3 col-lg-4 col-sm-6">
                    <div class="single-offer wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.1s">
                        <div class="media">
                            <img src="cdn/assets/img/startup/offer/1.png" alt="offer">
                            <div class="media-body align-self-center">
                                <h6>Challenging Project</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-sm-6">
                    <div class="single-offer wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.3s">
                        <div class="media">
                            <img src="cdn/assets/img/startup/offer/2.png" alt="offer">
                            <div class="media-body align-self-center">
                                <h6>Competitive salary</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-sm-6">
                    <div class="single-offer wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.5s">
                        <div class="media">
                            <img src="cdn/assets/img/startup/offer/3.png" alt="offer">
                            <div class="media-body align-self-center">
                                <h6>Play Zone</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-sm-6">
                    <div class="single-offer wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.7s">
                        <div class="media">
                            <img src="cdn/assets/img/startup/offer/4.png" alt="offer">
                            <div class="media-body align-self-center">
                                <h6>Strong Team</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-sm-6">
                    <div class="single-offer wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.8s">
                        <div class="media">
                            <img src="cdn/assets/img/startup/offer/5.png" alt="offer">
                            <div class="media-body align-self-center">
                                <h6>Decorated Office</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-sm-6">
                    <div class="single-offer wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="1.0s">
                        <div class="media">
                            <img src="cdn/assets/img/startup/offer/6.png" alt="offer">
                            <div class="media-body align-self-center">
                                <h6>Free Coffee &amp; Snacks</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    -->
    <!-- offer area End -->

    <!-- team area start -->
    <div class="team-area-wrpper sbst-team-area-wrpper">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-7 col-lg-10">
                    <div class="section-title text-center">
                        <h2 class="title wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.1s">New India Life Sciences 
                            Creative <span>Team</span></h2>
                        <p class="wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.2s">"At New India Life Sciences, creativity is at the heart of everything we do. Our dynamic team of creative minds is dedicated to reimagining healthcare solutions with passion and expertise. From brainstorming innovative concepts to crafting visually compelling designs, we thrive on pushing boundaries and inspiring positive change in the industry."<br>
                            innovative web development company. Our cost efficient and structured teamwork.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="team-area text-center">
            <div class="container">
                <div class="row custom-gutters-20">
                    <div class="col-lg-4 col-sm-6">
                        <div class="single-team wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.1s">
                            <div class="thumb">
                                <img src="cdn/assets/img/startup/team/2.png" alt="team">
                            </div>
                            <h6>
                                <router-link to="/ourteam">Mrs.  Kavita Rani</router-link>
                              </h6>
                            <span>Director</span>
                            <p>Director of Strategic Innovations and Growth</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="single-team wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.3s">
                            <div class="thumb">
                                <img src="cdn/assets/img/startup/team/3.png" alt="team">
                            </div>
                            <h6>
                               <router-link to="/ourteam">Dr. Ashutosh Kumar Singh</router-link>
                            </h6>
                            <span>Director</span>
                            <p>Director of Strategic Innovations and Growth</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="single-team wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.5s">
                            <div class="thumb">
                                <img src="cdn/assets/img/startup/team/1.png" alt="team">
                            </div>
                            <h6>
                                  <router-link to="/ourteam">Mr. Prateek Tripathi</router-link>
                             </h6>
                            <span>Director</span>
                            <p>Director of Strategic Innovations and Growth</p>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
    </div>
    <!-- team area end -->

    <!-- client area start -->
    <!--
    <div class="sbst-client-area pd-top-112">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-7 col-lg-10">
                    <div class="section-title text-center">
                        <h2 class="title">Our valuable <span>Client</span></h2>
                        <p>Riyaqas One of the best Software Development company. Riyaqas is at the forefront as the most
                            <br> innovative web development company. Our cost efficient and structured teamwork.
                        </p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-xl-10">
                    <div class="client-slider-2">
                        <div class="client-slider-2-item">
                            <a href="#" class="thumb">
                                <img src="cdn/assets/img/productthumb/p1.png" alt="client">
                            </a>
                        </div>
                        <div class="client-slider-2-item">
                            <a href="#" class="thumb">
                                <img src="cdn/assets/img/startup/client/2.png" alt="client">
                            </a>
                        </div>
                        <div class="client-slider-2-item">
                            <a href="#" class="thumb">
                                <img src="cdn/assets/img/startup/client/3.png" alt="client">
                            </a>
                        </div>
                        <div class="client-slider-2-item">
                            <a href="#" class="thumb">
                                <img src="cdn/assets/img/startup/client/4.png" alt="client">
                            </a>
                        </div>
                        <div class="client-slider-2-item">
                            <a href="#" class="thumb">
                                <img src="cdn/assets/img/startup/client/5.png" alt="client">
                            </a>
                        </div>
                        <div class="client-slider-2-item">
                            <a href="#" class="thumb">
                                <img src="cdn/assets/img/startup/client/1.png" alt="client">
                            </a>
                        </div>
                           <div class="client-slider-2-item">
                            <a href="#" class="thumb">
                                <img src="cdn/assets/img/startup/client/1.png" alt="client">
                            </a>
                        </div>
                           <div class="client-slider-2-item">
                            <a href="#" class="thumb">
                                <img src="cdn/assets/img/startup/client/1.png" alt="client">
                            </a>
                        </div>
                           <div class="client-slider-2-item">
                            <a href="#" class="thumb">
                                <img src="cdn/assets/img/startup/client/1.png" alt="client">
                            </a>
                        </div>
                           <div class="client-slider-2-item">
                            <a href="#" class="thumb">
                                <img src="cdn/assets/img/startup/client/1.png" alt="client">
                            </a>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
    -->
    <!-- client area End -->

</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src

@Options({
  components: {
    HelloWorld,
  },
})
export default class HomeView extends Vue {}
</script>
